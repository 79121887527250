import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import WebIcon from '@material-ui/icons/Web';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import domcard from "assets/img/domapotheke/logodomapotheke.png";
import falkencard from "assets/img/falkenapotheke/logofalkenapotheke.png";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <img style={{height: "100%", width: "100%", display: "block"}} src={domcard} alt="Domapotheke" />
                <CardBody>
                  <HomeIcon />
                  <h4>Dom-Apotheke</h4>
                  <h4>Russellplatz 2</h4>
                  <h4>45894 Gelsenkirchen</h4>
                  <br/>
                  <p><PhoneIcon style={{ fontSize: 12 }} /><a href="tel:+4920935980701"> +49 (0)209 35980701</a></p>
                  <p><PrintIcon style={{ fontSize: 12 }} /> +49 (0)209 35980702</p>
                  <p><EmailIcon style={{ fontSize: 12 }} /> <a href={'mailto:email@bf-apo.de'}>kontakt@dom-apotheke-buer.de</a></p>
                  <p><WebIcon style={{ fontSize: 12 }} /> <a href={'https://www.dom-apotheke-buer.de'}>www.dom-apotheke-buer.de</a></p>
                  <br/>
                  <Button color="success" size="lg" href={'https://www.meineapotheke.de/shop/dom-apotheke-gelsenkirchen-45894'}><ShoppingCartIcon/>zum Shop</Button>
                  <Button
                      color="danger"
                      size="lg"
                      href="https://www.meineapotheke.de/shop/dom-apotheke-gelsenkirchen-45894/rezept-einloesen"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <ReceiptIcon/>
                    Rezept einlösen
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <img style={{height: "100%", width: "100%", display: "block"}}
                      className={classes.imgCardTop} src={falkencard} alt="Falkenapotheke" />
                <CardBody>
                  <HomeIcon />
                  <h4>Buersche Falken-Apotheke</h4>
                  <h4>Goldbergplatz 1 / Hochstr. 1</h4>
                  <h4>45894 Gelsenkirchen</h4>
                  <br/>
                  <p><PhoneIcon style={{ fontSize: 12 }} /><a href="tel:+4920933727">  +49 (0)209 33727</a></p>
                  <p><PrintIcon style={{ fontSize: 12 }} /> +49 (0) 209 337699</p>
                  <p><EmailIcon style={{ fontSize: 12 }} /> <a href={'mailto:email@bf-apo.de'}>email@bf-apo.de</a></p>
                  <p><WebIcon style={{ fontSize: 12 }} /> <a href={'https://www.buersche-falken-apotheke.de'}>www.buersche-falken-apotheke.de</a></p>
                  <br/>
                  <Button color="success" size="lg" href={'https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894'}><ShoppingCartIcon/>zum Shop</Button>
                  <Button
                      color="danger"
                      size="lg"
                      href="https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894/rezept-einloesen"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <ReceiptIcon/>
                    Rezept einlösen
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
